<template>
  <div>
    <vue-bootstrap4-table
        :rows="data"
        :columns="columns"
        :config="config"
        :actions="actions"
        @on-change-query="onChangeQuery"
        @createAbsence="onClickCreateAbsence"
        :total-rows="totalRows">

      <template slot="user_name" slot-scope="props">
        <span class="vbt-table-td">
          {{ props.row.user.detail.first_name }} {{ props.row.user.detail.last_name }}
        </span>
      </template>

      <template slot="status" slot-scope="props">
        <div class="vx-row">
           <span class="mg-label" :class="getStatusClassNameById(props.row.status)">
                {{ getStatusById(props.row.status).label || null }}
           </span>
          <vx-tooltip :text="'Kommentar Mitarbeiter: '+props.row.comment" position="top" v-if="props.row.comment">
                <feather-icon icon="MessageCircleIcon" ></feather-icon>
          </vx-tooltip>
          <vx-tooltip :text="'Kommentar Vorgesetzter: '+props.row.rejection_reason" position="top"  v-if="props.row.rejection_reason">
                <feather-icon icon="MessageCircleIcon" ></feather-icon>
          </vx-tooltip>
        </div>
      </template>

      <template slot="row_actions" slot-scope="props">
        <div class="column-actions-group">
          <vs-button color="success"
                     type="filled"
                     icon="edit"
                     size="small"
                     @click="onClickEditAbsence(props.row)">
          </vs-button>
        </div>
      </template>
        <template slot="table-last-row">
            <tr class="vbt-row-summary" >
                <td colspan="2">
                    <span class="vbt-table-td">{{ total_rows }} Ergebnisse</span>
                </td>
                <td colspan="4" align="right"></td>
                <td colspan="2">
                    <span class="vbt-table-td"><b>Gesamt: {{ pageSum }}</b></span>
                </td>
            </tr>
        </template>
    </vue-bootstrap4-table>

    <absence
        :absence="currentAbsence"
        :active.sync="absencePopupActive"
        :absence-types="absenceTypes"
        :absence-quotas="absenceQuotas"
        :users="users"
        :user-selection="userSelection"
        @delete="onDeleteAbsence"
        @save="onSaveAbsence">
    </absence>

  </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'
import ApiService from "../../api";
import {mapGetters} from "vuex";
import * as qs from "qs";
import QueryHelper from "../../mixins/helper/query.helper";
import AbsenceStatusOptions from "./absence-status-options";
import ModelFactory from "../../mixins/model/model-factory";
import moment from 'moment';
import Absence from "./components/Absence";
import PriceHelper from "@/mixins/helper/price.helper";

export default {
  name: "AbsenceIndex",

  components: {
    Absence,
    VueBootstrap4Table
  },

  data() {
    return {
      data: [],
      columns: [],
      totalRows: 0,
      config: {
        checkbox_rows: false,
        show_refresh_button: false,
        show_reset_button: false,
        highlight_row_hover_color: '#f8f8f8',
        server_mode: true
      },
      actions: [
        {
          btn_text: "Hinzufügen",
          event_name: 'createAbsence',
          color: 'success'
        }
      ],

      currentAbsence: null,
      absencePopupActive: false,
      absenceQuotas: [],
      absenceTypes: [],
      users: [],
      userSelection: true,
      currentQuery: null
    }
  },

  computed: {
    ...mapGetters(['userHasPermission', 'userId']),
      pageSum() {
          if (!this.data) {
              return 0;
          }

          const sum = this.data.reduce((acc, item) => {
              return acc + item.daysCount;
          }, 0);

          return sum;
      }
  },

  created() {
    this.userSelection = this.userHasPermission('can_manage_absence_requests');
    this.fetchData();
    this.createColumns();
  },

  methods: {
    fetchData: function () {
      this.timeSheet = null;

      const promises = [ApiService.get(`absence-type`)];

      if (this.userHasPermission('can_manage_absence_requests')) {
        promises.push(ApiService.get('users?includes=absenceQuotas'));
      } else {
        promises.push(ApiService.get(`users/${this.userId}`));
      }

      Promise.all(promises).then(responses => {
        this.absenceTypes = responses[0].data.result;

        if (this.userHasPermission('can_manage_absence_requests')) {
          this.users = responses[1].data.result;
        } else {
          this.absenceQuotas = responses[1].data.result.absence_quotas;
        }
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });
    },

    createColumns() {
      const columns = [];

      columns.push({
        label: "Benutzer",
        name: "user.detail.first_name",
        slot_name: "user_name",
        sort: true,
        row_text_alignment: 'text-left',
        column_text_alignment: 'text-left',
        filter: {
          type: "simple",
          placeholder: "Suchen..."
        },
      });

      columns.push({
        label: "Status",
        name: "status",
        slot_name: "status",
        sort: true,
        row_text_alignment: 'text-left',
        column_text_alignment: 'text-left',
        filter: {
          type: 'select',
          options: AbsenceStatusOptions,
          mode: 'single',
        },
      });

      columns.push({
        label: "Typ",
        name: "absence_type.name",
        sort: true,
        row_text_alignment: 'text-left',
        column_text_alignment: 'text-left',
        filter: {
          type: "simple",
          placeholder: "Suchen..."
        },
      });

      columns.push({
        label: "Ab",
        name: "starts_at",
        sort: true,
        row_text_alignment: 'text-left',
        column_text_alignment: 'text-left',
        filter: {
            type: "simple",
            placeholder: "Suchen..."
        },
        formatter: (value) => {
          return moment(new Date(value)).format('DD.MM.YY');
        },
      });

      columns.push({
        label: "Tage",
        name: "daysCount",
        sort: false,
        row_text_alignment: 'text-left',
        column_text_alignment: 'text-left'
      });

      columns.push({
        label: "Actions",
        slot_name: "row_actions",
        sort: false,
        row_text_alignment: 'text-left',
        column_text_alignment: 'text-left',
        column_classes: 'actions-column',
        showCol: true,
        width: '80px',

      });

      this.columns = columns;
    },
    onChangeQuery(query) {
      this.$vs.loading()
      this.currentQuery = query;

      const requestParams = QueryHelper.parseRequestParams(query);
      requestParams.includes = 'user.detail'
      requestParams.appends = 'daysCount'

      if (!this.userHasPermission('can_manage_absence_requests')) {
        requestParams.filter.push({
          field: 'user_id',
          expression: 'exact',
          value: this.userId
        });
      }

      ApiService.get('absence', {
        params: requestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.data = response.data.result;
        this.totalRows = response.data.total;
        this.$vs.loading.close()
      })
    },

    getStatusById(id) {
      return AbsenceStatusOptions.find(item => item.value === parseInt(id));
    },

    getStatusClassNameById(id) {
       if(parseInt(id) == 1) {
         return "success"
       } else if(parseInt(id) == 3){
         return "dark"
       } else if(parseInt(id) == 2){
         return "danger"
       } else {
         return "gray"
       }
    },

    onClickCreateAbsence() {
      const absence = ModelFactory.create('absences');

      absence.starts_at = moment().format('YYYY-MM-DD HH:mm:ss');
      absence.whole_day = true;

      if (!this.userHasPermission('can_manage_absence_requests')) {
        absence.user_id = this.userId;
      }

      this.openAbsencePopup(absence);
    },

    onClickEditAbsence(absence) {
      delete absence.created_at;
      delete absence.updated_at;

      this.openAbsencePopup(absence);
    },

    openAbsencePopup(absence) {
      this.currentAbsence = absence;
      this.absencePopupActive = true;
    },

    onDeleteAbsence(absence) {
      ApiService.delete('absence/' + absence.id).then(response => {
        this.fetchData();
        this.onChangeQuery(this.currentQuery);
        this.absencePopupActive = false;
        response.successNotifier('Die Abszenz wurde gelöscht');
      });
    },

    onSaveAbsence(absence) {
      if (!absence.id && !this.userHasPermission('can_manage_absence_requests')) {
        absence.status = 3;
      }

      if (absence.id !== undefined && absence.id !== null) {
        ApiService.put('absence/' + absence.id, absence).then(response => {
          this.fetchData();
          this.onChangeQuery(this.currentQuery);
          this.absencePopupActive = false;
          response.successNotifier('Die Abszenz wurde gespeichert');
        });
        return;
      }

      ApiService.post('absence', absence).then(response => {
        this.fetchData();
        this.onChangeQuery(this.currentQuery);
        this.absencePopupActive = false;
        response.successNotifier('Die Abszenz wurde erstellt');
      });
    }
  }
}
</script>

<style scoped>

</style>